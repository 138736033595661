import React from 'react'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { CallToAction, GetStarted } from '../../components/CallToAction'
import { Quote } from '../../components/Quote'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';

import SfOnLaptop from '../images/sfOnLaptop.png'
import SfNotes from '../images/sfNotes.svg'
import TeamDashHalf from '../images/teamDashHalf.svg'
import OldWay from '../images/oldWay.svg';
import ActionEvent from '../images/actionEvent.svg';
import SalesforceDashboard from '../images/salesforceDashboard.png'



const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | Better Way To Scale Deal Management</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='headsetPerson2'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            Automate Your Pipeline Inspection
          </PageTitle2>
          <PageTitle as="p" color={colors.white} mb="1.5rem" center>
            Ensure Every Opportunity Is Followed Up On, Based On Your Process
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>


    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={OldWay} alt="Old way of managing sales"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          STEP 1: ACHIEVE DATA CONFIDENCE
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
           Sales leaders often have low confidence in their CRM  data, which means every coaching/deal review needs to be supplemented with hours of additional discovery (rep anecdotes, checking multiple systems, etc.)
           <br/><br/>
             This means only a fraction of deals get managerial review/guidance, and revenue slips through the cracks.
          <br/><br/>
            Truly was built from the ground up to get every touch point, across every channel, into the right views in CRM, so there's no doubt to slow your team down.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
          STEP 2: QUANTIFY EVERYTHING
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
           Once you have all the data in one place,  every aspect of the rep's sales execution can then be quantified into metrics using sales math and AI.  
           <br/><br/>
           These metrics then help you identify whether an issue exists (no next step exists), and if it's a skill problem (talks too much) or a will problem (no follow ups).  
          <br/><br/>
           Truly platform offers the most advanced suite of sales execution metrics in the market 'out of the box', while also making it possible to create your own without writing any code.
          </Large>
        </Box>
        <Box width={[1/2, 1/2]}>
          <CenteredImg src={SalesforceDashboard} alt="Quantify all sales data"/>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={ActionEvent} alt="detect isues and automate"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          STEP 3: DETECT, REPORT AND AUTOMATE
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Now that you have custom reporting metrics you can trust, you can start automating your pipeline management activities.
            <br/><br/>
            Automatically update CRM fields based on customer conversations, score/flag deals at risk, notify reps/managers when a deal falls outsidee the process, and create follow up tasks for your reps with specific guidance on what to do next.
            <br/><br/>
            With Truly inspecting your pipeline continuously in the background, you never have to worry about revenue falling through the cracks. 

          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperBackground background="headsetPerson1" gradientType="purple">
      <CallToAction/>
    </WrapperBackground>

    <WrapperBackground background="sf" gradientType="black">
      <GetStarted/>
    </WrapperBackground>

  </Default>
)

export default SolutionsPage

